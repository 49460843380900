import React, { useEffect, useMemo } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { useUIContext } from "../UIContext"
import { firestoreOld } from "../../../../firebase"
import { liveDataSlice } from "../../_redux/liveData/liveDataSlice"
import { LiveMapWidget } from "./LiveMap/LiveMapWidget"
import "../../../_assets/sass/pages/dashboard/_dashboard-geral-info-row.scss"
import { TotalRoleOccupancyCard } from "./TotalRoleOccupancy/TotalRoleOccupancyCard"
import { TotalAreaOccupancyCard } from "./TotalZoneOccupancy/TotalAreaOccupancyCard"
import { TopRowInfo } from "./TopRowInfo/TopRowInfo"
import { AttendanceListCard } from "./AttendanceList/AttendanceListCard"
import * as liveDataActions from "../../_redux/liveData/liveDataActions"
import { Timestamp } from "firebase/firestore"
import { isEqual, cloneDeep } from "lodash"
import moment from "moment"
import { StickyLiveButton } from "./StickyLiveButton"
// import geohash from "ngeohash"

export function LiveDataSection() {
	const nodesCollection = global.nodesCollection

	const dispatch = useDispatch()

	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			liveData: UIContext.liveData,
			setLiveData: UIContext.setLiveData,
			resetSnapshot: UIContext.resetSnapshot,
			setResetSnapshot: UIContext.setResetSnapshot
		}
	}, [UIContext])

	//━━━━━━━━━━━━━ Selectors ━━━━━━━━━━━━━\\

	const {
		selectedCustomer,
		selectedSite,
		people,
		assets,
		liveDataTimeoutSeconds,
		tagSeenTimeout,
		// tags,
		activeTags,
		user,
		anchors,
		selectedFloorPlan
	} = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			people: state.basePage?.people,
			assets: state.basePage?.assets,
			liveDataTimeoutSeconds: state.basePage?.globalOptions?.generalInfo?.liveDataTimeoutSeconds,
			tagSeenTimeout: state.basePage?.tagSeenTimeout,
			// tags: state.liveData?.tags,
			activeTags: state.liveData?.activeTags,
			user: state.auth?.user,
			anchors: state.liveData.anchors,
			selectedFloorPlan: state.profile?.currentFloorPlan
		}),
		shallowEqual
	)

	//━━━━━━━━━━━━━ States ━━━━━━━━━━━━━\\
	const [newTagsData, setNewTagsData] = React.useState([]) // fetched tags
	//━━━━━━━━━━━━━ useEffects ━━━━━━━━━━━━━\\
	useEffect(() => {
		if (!selectedCustomer || !selectedSite) return

		UIProps.setLiveData(true)

		const unsubscribe = firestoreOld
			.collection(`Sites/${selectedSite.id}/${nodesCollection}`)
			.where("nodeType", "==", "tag")
			.onSnapshot(
				snapshot => {
					const newTags =
						snapshot.docs.map(doc => {
							const data = doc.data()

							// Transform lastSeen property if it is a string to Timestamp
							if (typeof data.lastSeen === "string") {
								data.lastSeen = Timestamp.fromDate(new Date(data.lastSeen))
							}

							return { id: doc.id, ...data }
						}) || []

					setNewTagsData(newTags) // passing the newTags to a state, (instead of passing right to redux)
					// console.log("🚀🚀🚀 ~ useEffect ~ newTags:", newTags)

					// // dispatch(liveDataSlice.actions.tagsFetched(newTags)) // not used anymore
				},

				error => console.error(error)
			)

		if (selectedSite.siteInfo?.cityDocId) {
			var unsubscribeWeather = firestoreOld
				.collection("Weather")
				.doc(selectedSite.siteInfo.cityDocId)
				.onSnapshot(
					doc => {
						if (!doc.exists) {
							dispatch(liveDataSlice.actions.weatherFeched())
							return
						}
						var data = { id: doc.id, ...doc.data() }
						dispatch(liveDataSlice.actions.weatherFeched(data))
					},
					error => console.error(error)
				)
		} else {
			dispatch(liveDataSlice.actions.weatherFeched())
		}

		const timer = setTimeout(
			() => {
				UIProps.setLiveData(false)
				unsubscribe()
				unsubscribeWeather && unsubscribeWeather()
			},
			liveDataTimeoutSeconds ? liveDataTimeoutSeconds * 1000 : 600000
		)
		return () => {
			unsubscribe()
			unsubscribeWeather && unsubscribeWeather()
			clearTimeout(timer)
		}
	}, [selectedCustomer, selectedSite, UIProps.resetSnapshot, liveDataTimeoutSeconds])

	// ━━━━━━━━━━ Fetch all Points of Interest and Icons + Anchors
	useEffect(() => {
		if (!selectedSite) return

		// Dispatch actions for fetching POIs and icons
		dispatch(
			liveDataActions.fetchPois({
				siteId: selectedSite.id
			})
		)
		dispatch(liveDataActions.fetchIconsFromDB())

		// Fetch anchors only if they haven't been fetched yet
		// if (!anchors) {
		const fetchAnchors = async () => {
			try {
				const snapshot = await firestoreOld
					.collection(`Sites/${selectedSite.id}/${nodesCollection}`)
					.where("floorPlanId", "==", selectedFloorPlan.id)
					.where("nodeType", "==", "anchor")
					.get()

				const newAnchors = snapshot.docs.map(doc => ({
					id: doc.id,
					...doc.data()
				}))

				if (newAnchors && newAnchors.length > 0) {
					dispatch(liveDataSlice.actions.addAnchors(newAnchors))
				}
			} catch (error) {
				console.error("Error fetching anchors:", error)
			}
		}

		fetchAnchors() // Call the async function
		// }
	}, [selectedSite, selectedFloorPlan]) // Ensure proper dependencies

	// ━━━━━━━━━━ Fetch all Areas
	useEffect(() => {
		if (!selectedSite) return

		dispatch(
			liveDataActions.fetchAreasInDB({
				siteId: selectedSite.id
			})
		)
	}, [selectedSite])

	// ━━━━━━━━━━ Fetch user drawer preferences
	useEffect(() => {
		if (!selectedSite && !user) return

		user?.id &&
			dispatch(
				liveDataActions.fetchUserPreferencesInDB({
					userId: user?.id
				})
			)
	}, [selectedSite, user])

	// Add the person assigned to the tag directly to it
	// And filter active tags to separate all from active
	// This helps reduce renders and filters/finds
	useEffect(() => {
		if (!people || !newTagsData) {
			// dispatch(liveDataSlice.actions.tagsWithPeopleFetched(newTagsData)) // if !newTagsData dispatch empty array to redux and return
			return
		}

		const newTags = newTagsData
			.filter(t => {
				if (!t?.firstSeen || !t?.lastSeen) {
					return false
				}

				let firstSeenDate
				let lastSeenDate

				if (t.firstSeen instanceof Timestamp) {
					firstSeenDate = t.firstSeen.toDate()
				} else if (typeof t.firstSeen === "string") {
					firstSeenDate = new Date(t.firstSeen)
				} else {
					return false
				}

				if (t.lastSeen instanceof Timestamp) {
					lastSeenDate = t.lastSeen.toDate()
				} else if (typeof t.lastSeen === "string") {
					lastSeenDate = new Date(t.lastSeen)
				} else {
					return false
				}

				// Filter tags if the firstSeen and lastSeen have a difference greater than 5m
				return moment(lastSeenDate).diff(moment(firstSeenDate), "minutes") >= 5
			})
			.map(tag => {
				const person = people && people.find(val => val.uuid === tag.uuid)

				var updatedTag = cloneDeep(tag)
				if (person) {
					updatedTag.person = person
				} else {
					updatedTag.person = null
				}

				const asset = assets && assets.find(val => val.uuid === tag.uuid)
				if (asset && !person) {
					updatedTag.asset = asset
				} else {
					updatedTag.asset = null
				}

				if (tag.lastSeen) {
					const lastSeen = moment(tag.lastSeen.toDate())
					const xTimeAgo = moment(Timestamp.now().toDate()).subtract(tagSeenTimeout, "seconds")
					if (lastSeen.isAfter(xTimeAgo)) {
						updatedTag.tagState = "active"
					} else {
						updatedTag.tagState = "inactive"
					}
				}

				return updatedTag
			})

		// if (!isEqual(newTagsData, newTags)) {
		dispatch(liveDataSlice.actions.tagsFetched(newTags))
		// This was created due to tagsFetched being update 2 time,
		// one on snapshot and the other when adding people to those tags
		// Like this we onlu update tagsWithPeopleFetched once after tags are update and we add people

		// add to remove because like this if the values become zero it doenst update
		// also, i think tagsWithPeopleFetched or this is used globaly or has it is we should remove it
		dispatch(liveDataSlice.actions.tagsWithPeopleFetched(newTags))
		// }

		const newActiveTags = newTags.filter(tag => tag.tagState === "active")

		// console.log(
		// 	"🔫 ~ active: ",
		// 	newTags.filter(tag => tag.tagState === "active")
		// )
		// console.log(
		// 	"🔫 ~ inactive: ",
		// 	newTags.filter(tag => tag.tagState === "inactive")
		// )

		if (!isEqual(activeTags, newActiveTags)) {
			dispatch(liveDataSlice.actions.activeTagsFetched(newActiveTags))
		}
	}, [people, assets, newTagsData])

	return (
		<>
			{selectedSite && (
				<div className="col-12" style={{ marginBottom: "2rem" }}>
					<TopRowInfo />
				</div>
			)}
			<div className="col-12">
				<LiveMapWidget />
			</div>
			{selectedSite && (
				<>
					<TotalRoleOccupancyCard />
					<TotalAreaOccupancyCard />
					<div className="col-12">
						<AttendanceListCard />
					</div>
					<StickyLiveButton />
				</>
			)}
		</>
	)
}

// useEffect(() => {
// 	if (!selectedFloorPlan) return
// 	// if (!areas) return
// 	// tranformAreaIntoGeohashArray(areas[0])
// 	console.log("UseEffect start")

// 	// Example usage (2 corners of a box where tags wich are inside will be fetched in the current example it is Area 1 of floorplan level 2 of Dozer Construction )
// 	const corner1latitude = 39.28363582830548
// 	const corner1longitude = -76.55240192781272
// 	const corner2latitude = 39.28424503119146
// 	const corner2longitude = -76.5514870082217
// 	const precision = 20 // Adjust precision as needed
// 	// const radiusKm = 0.1

// 	const corner1Geohash = geohash.encode(corner1latitude, corner1longitude, precision)
// 	const corner2Geohash = geohash.encode(corner2latitude, corner2longitude, precision)
// 	const corner1geohashPrefix = corner1Geohash.substring(0, precision)
// 	const corner2geohashPrefix = corner2Geohash.substring(0, precision)

// 	// Query Firestore for documents with geohash values that match the prefix.
// 	const query = firestoreOld
// 		.where("floorPlanId", "==", selectedFloorPlan)
// 		.where("geohash", ">=", corner1geohashPrefix)
// 		.where("geohash", "<", corner2geohashPrefix + "\uf8ff")

// 	const unsubscribe = query.onSnapshot(snapshot => {
// 		console.log("Onsnapshot ran")
// 		console.log("🚀 . snapshot empty:", snapshot.empty)
// 		snapshot.forEach(doc => {
// 			const tag = doc.data()

// 			if (tag.lastSeen) {
// 				const lastSeen = moment(tag.lastSeen.toDate())
// 				const xTimeAgo = moment(Timestamp.now().toDate()).subtract(tagSeenTimeout, "seconds")
// 				if (lastSeen.isAfter(xTimeAgo)) {
// 					console.log("🚀 . tag:", tag)
// 				} else {
// 					// console.log("🚀 . inactive")
// 				}
// 			}
// 		})
// 	})
// 	// .catch(error => {
// 	// 	console.error("Error getting documents:", error)
// 	// })

// 	return () => unsubscribe && unsubscribe()
// }, [selectedFloorPlan])
