import React, { useState, useEffect, useRef, useMemo } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import {
	addImageLayerToMap
	// addZoneMarkers,
	// removePathLayers,
	// addPOIMarkers,
	// addGeoJsonLayerToMapLine,
} from "../_helpers/ActionHelpers"
import {
	addGeoJsonLayerToMapWithNewPath,
	addConnectorsMarkers,
	removePathLayers
} from "../_helpers/PathActionHelpers"
import { allLocationsInMap } from "../_helpers/NoLocationHelpers"
import { addPOIMarkers } from "../_helpers/POIHelpers"
import { addZoneMarkers, peopleCountInAreas } from "../_helpers/AreaHelpers"
import * as ActionHelpers from "../_helpers/ActionHelpers"
import { useUIContext } from "../../UIContext"
import Fullscreen from "./Controls/Fullscreen"
import MapDimensions from "./Controls/MapDimensions"
import { Card, CardHeader, CardBody } from "../../../../_partials/Card"
import "../LiveMapStyle.css"
import "../LiveButton.scss"
import "./Controls/Controls.css"
import "../thermostatMarker.scss"
import AlertGeofencing from "./AlertGeofencingModal/AlertGeofencing"
import moment from "moment"
import ChangeFloorControlDashboard from "./Controls/ChangeFloorControlDashboard"
import { profileSlice } from "../../../../redux/profile/profileSlice"
import { notificationsSlice } from "../../../../redux/notifications/notificationsSlice"
import { SendMessageModal } from "./SendMessageModal/SendMessageModal"
import DrawerOptions from "./Controls/DrawerOptions"
import { uniqAssetDetailsTypes } from "../_helpers/DrawerHelpers"
import {
	hideMarkersWhenClustering,
	hideMarkersWhenClusteringOnZoom,
	hideTagsHtmlWhenClustering
} from "../_helpers/HideMarkersHelpers"
import MapboxglSpiderifier from "mapboxgl-spiderifier"
import { initializeSpiderLeg, mouseClick, removePopupSticky } from "../_helpers/SpiderifierHelpers"
import { addHeatmaptoMap } from "../_helpers/HeatmapHelpers"
import { activateGeofence } from "../_helpers/GeofenceHelpers"
import { filterPreferencesHandler } from "../_helpers/FilterPreferencesHelpers"
import { handleSearchChange, resetZoomOnMap } from "../_helpers/SearchHelpers"

const { actions } = profileSlice
const notificationsActions = notificationsSlice.actions

export function LiveMapWidget() {
	const dispatch = useDispatch()

	// ━━━ User based information ━━━\\
	const {
		user,
		customClaims,
		selectedCustomer,
		selectedSite,
		selectedFloorPlan,
		floorPlans,
		roles,
		assetTypes,
		people,
		assets,
		tags,
		gateways,
		anchors,
		areas,
		pointsOfInterest,
		iconsFromDB,
		drawerPreferences,
		mapViewPreferences,
		notificationWarning,
		companies,
		isSuper,
		isAdmin,
		isSiteAdmin
	} = useSelector(
		state => ({
			user: state.auth?.user,
			customClaims: state.auth?.claims,
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			selectedFloorPlan: state.profile?.currentFloorPlan,
			floorPlans: state.basePage.floorPlans,
			roles: state.profile?.currentCustomer?.roles,
			assetTypes: state.basePage?.assetTypes,
			people: state.basePage.people,
			assets: state.basePage.assets,
			tags: state.liveData.activeTags,
			gateways: state.liveData.gateways,
			anchors: state.liveData.anchors,
			// areas: state.basePage.areas,
			areas: state.liveData.areas,
			pointsOfInterest: state.liveData.pois,
			iconsFromDB: state.liveData.iconsFromDB,
			drawerPreferences: state.liveData.drawerPreferences,
			mapViewPreferences: state.liveData.mapViewPreferences,
			notificationWarning: state.notifications?.notificationWarning,
			companies: state.profile?.currentCustomer?.vendors,
			isSuper: state.auth?.claims?.isSuper,
			isAdmin: state.auth?.claims?.isAdmin,
			isSiteAdmin: state.auth?.claims?.isSiteAdmin
		}),

		shallowEqual
	)

	const access = isSuper
	// || (isAdmin && !isSiteAdmin)

	// ━━━ Data from UIContext ━━━\\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			liveData: UIContext.liveData,
			setLiveData: UIContext.setLiveData,
			resetSnapshot: UIContext.resetSnapshot,
			setResetSnapshot: UIContext.setResetSnapshot
		}
	}, [UIContext])

	// ━━━━━━━━━━━━━━━ States ━━━━━━━━━━━━━━━ \\
	// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\
	const mapContainerRef = useRef(null)
	const [map, setMap] = useState()
	const [markers, setMarkers] = useState([]) // Tags / People markers
	const [sendMessageModal, setSendMessageModal] = useState()
	const [searchControl, setSearchControl] = useState()
	const [searchFilteredOptions, setSearchFilteredOptions] = useState()
	const [hideSearchBar, setHideSearchBar] = useState(true)
	const [goTo, setGoTo] = useState()
	const [showGateways, setShowGateways] = useState(false)
	const [gatewaysMarkers, setGatewaysMarkers] = useState()
	const [showAnchors, setShowAnchors] = useState(false)
	const [anchorsMarkers, setAnchorsMarkers] = useState()
	const [floorsControl, setFloorsControl] = useState() // Controls to select floorplan
	const [showMockMarkers, setShowMockMarkers] = useState(false) // Mock machines markers
	const [openAlertModal, setOpenAlertModal] = useState(false) // Mock machines alert modal
	const [minutesSinceLastAlert, setMinutesSinceLastAlert] = useState(
		moment().subtract(1, "day") // Mock machines alert modal
	)
	const [isLoading, setIsLoading] = useState(false) // Messaging
	const [locationsMarkers, setLocationsMarkers] = useState() // No site selected markers
	const [updatedCurrentFloorPlan, setUpdatedCurrentFloorPlan] = useState() // updated current floorplan with all data updated from redux (geoJson / geoJson routes / areas) without need of refreshing after creating on design studio
	// ↓ Indoor Navigation
	const [directionsControl, setDirectionsControl] = useState() // Indoor Navigation
	const [getDirections, setGetDirections] = useState({
		type: null,
		feature: null,
		clicked: false
	}) // starting point destination
	const [pathFound, setPathFound] = useState()
	const [connectorsMarkers, setConnectorsMarkers] = useState()
	const [showConnectors, setShowConnectors] = useState(true) // Show Connectors icons
	const [connectorAccess, setConnectorAccess] = useState({
		clicked: false,
		zIndex: "0",
		// className: "popupLineRoutes",
		id: null
	})
	const [iconTab, setIconTab] = useState(0)
	const [typeByDefault, setTypeByDefault] = useState("ELEVATOR")
	const [resetPathControl, setResetPathControl] = useState()
	const [clickedToResetPath, setClickedToResetPath] = useState(false)
	const [copyOfStartPointCoords, setCopyOfStartPointCoords] = useState()
	const [copyOfConnectorCoords, setCopyOfConnectorCoords] = useState()
	// const [travelTo, setTravelTo] = useState() // ending point destination // not used anymore now is endingPoint
	const [poiToDoorCoords, setPoiToDoorCoords] = useState()
	const [poiToDoorEndPointCoords, setPoiToDoorEndPointCoords] = useState()
	const [endingPoint, setEndingPoint] = useState() // new ending point destination
	const [startingPoint, setStartingPoint] = useState() // new starting point destination
	// ↓ Areas markers
	const [areaMarkers, setAreaMarkers] = useState([]) // areas markers
	const [showAreaInfoMarkers, setShowAreaInfoMarkers] = useState(true) // areas markers
	const [poiMarkers, setPoiMarkers] = useState() // POI markers
	const [showPOIs, setShowPOIs] = useState(true) // Show POI markers toggle
	const [oldTags, setOldTags] = useState([])
	const [isFullscreen, setIsFullscreen] = useState(false)
	const [tagsUpdatedWithFullscreen, setTagsUpdatedWithFullscreen] = useState(false)
	const [showTags, setShowTags] = useState(true)
	const [showPeople, setShowPeople] = useState(true)
	const [showAssets, setShowAssets] = useState(true)
	const [showUnassignedTags, setShowUnassignedTags] = useState(true)
	// ↓ drawer options control state
	const [drawerControl, setDrawerControl] = useState()
	const [openDrawerBar, setOpenDrawerBar] = useState(false)
	const [drawerOptions, setDrawerOptions] = useState({
		openSearch: false,
		openFilter: false,
		openPath: false,
		openOccupancy: false,
		openSatellite: false,
		openSlider: false,
		open3d: false,
		openHeatmap: false
	})
	const [showPathOnMap, setShowPathOnMap] = useState(false) // show path on map
	const [selectedConnector, setSelectedConnector] = useState()
	const [showRoles, setShowRoles] = useState(true) // roles markers on map
	const [clickedRolesButton, setClickedRolesButton] = useState(true) // clicked roles button
	const [roleFilterButtonStates, setRoleFilterButtonStates] = useState(
		roles && roles.map(rol => rol.name || [])
	)
	const [differentAssetTypes, setDifferentAssetTypes] = useState([]) // assets filtered for each unique type
	const [assetFilterButtonStates, setAssetFilterButtonStates] = useState(
		(differentAssetTypes &&
			differentAssetTypes.map(asset => asset?.assetDetails?.type && asset.assetDetails?.type)) ||
			[]
	)
	const [mapStyleView, setMapStyleView] = useState(
		mapViewPreferences?.style ? mapViewPreferences.style : "streets-v11"
	) // change the map style url
	const [mapStyleChanged, setMapStyleChanged] = useState(false) // everytime the map style view is changed we pass that dependency in the useEffect of markers so they re-appear on top of the new style
	const [searchedPersonOrAssetOld, setSearchedPersonOrAssetOld] = useState(null)
	// ↓ drawer options markers size slider value state
	const [markersSize, setMarkersSize] = useState(null)
	// layers displayed states
	const [showLayersBesidesTags, setShowLayersBesidesTags] = useState(true)
	// Markers Size
	const [peopleSize, setPeopleSize] = useState(null)
	const [assetsSize, setAssetsSize] = useState(null)
	const [POISSize, setPOISSize] = useState(null)
	const [anchorsSize, setAnchorsSize] = useState(null)
	const [gatewaysSize, setGatewaysSize] = useState(null)
	const [unassignedTagsSize, setUnassignedTagsSize] = useState(null)
	const [areasSize, setAreasSize] = useState(null)
	// heatmap filter
	const [heatmapFilter, setHeatmapFilter] = useState({
		displayPeople: true,
		displayAssets: true,
		displayUnassigned: true
	})
	// Geofence Activation
	const [geofencePulseMarkers, setGeofencePulseMarkers] = useState([])
	const [peopleInsideEachArea, setPeopleInsideEachArea] = useState([]) // Tags inside each area count
	const [newStyleLayer, setNewStyleLayer] = useState(false) // logic to avoid adding the new image with zoom
	const [newSearchedTag, setNewSearchedTag] = useState(null)
	const [typingSearch, setTypingSearch] = useState(false)

	// ━━━━━━━━━━━━━━━ UseEffects ━━━━━━━━━━━━━━━ \\
	// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\

	// Display Map
	useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: mapViewPreferences?.style
				? `mapbox://styles/mapbox/${mapViewPreferences.style}`
				: "mapbox://styles/mapbox/streets-v11",
			// style: "mapbox://styles/mapbox/streets-v11",
			maxZoom: 24,
			attributionControl: false
		})

		map.on("load", function () {
			map.addControl(new Fullscreen(), "top-left")
			map.addControl(new MapDimensions(), "bottom-left")
			setMap(map)
		})

		map.on("wheel", event => {
			if (event.originalEvent.ctrlKey) return
			if (event.originalEvent.metaKey) return
			if (event.originalEvent.altKey) return

			dispatch({
				type: "SNACKBAR_WARNING",
				payload: `Use CTRL + Scroll for zoom`
			})

			event.preventDefault()
		})

		document.addEventListener("fullscreenchange", function () {
			if (document.fullscreenElement) {
				setIsFullscreen(true)
			} else {
				setIsFullscreen(false)
			}
		})

		map.doubleClickZoom.disable() // ━━ doubleClickZoom disabled

		// return () => map.remove()
		return () => {
			if (!map) return
			setMap(prevMap => {
				if (prevMap) {
					prevMap.remove()
				}
				return null
			})
		}
	}, [])

	// ━━━━━━━ Event handler for changing the map style
	const changeMapStyle = style => {
		const styleUrl = `mapbox://styles/mapbox/${style}`

		map.setStyle(styleUrl) // Change map style

		map.once("style.load", () => {
			// Add floorplan image layer and Geojson Areas, and all markers again after changing the style
			// everytime the map style view is changed we pass that dependency in the useEffect of markers so they re-appear on top of the new style
			setMapStyleChanged(prevState => {
				if (!prevState) {
					setNewStyleLayer(false) // logic to avoid adding the new image with zoom
					return !prevState
				} else {
					setNewStyleLayer(true)
					return !prevState
				}
			})
		})
	}

	// ━━━━━━━━━━━━ 3D Button  ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map) return

		const bottomLeft = document.getElementsByClassName("mapboxgl-ctrl-bottom-left")

		if (bottomLeft && bottomLeft?.[0]) {
			bottomLeft.forEach(element => {
				element.style.display = "flex"
			})
		}
	}, [map])

	// ━━━━━━━━━━━━ Updated Current Floorplan  ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!floorPlans || !selectedFloorPlan) return

		// ↓ (this is needed to fetch the floorplan with all updates like new areas, routes, etc. selectedFloorPlan hasn't the data updated.
		const updatedFloorplanInFloorPlanData =
			floorPlans && floorPlans.find(f => f.id === selectedFloorPlan.id)

		updatedFloorplanInFloorPlanData && setUpdatedCurrentFloorPlan(updatedFloorplanInFloorPlanData)
	}, [floorPlans, selectedFloorPlan])

	// ━━━━━━━━━━━ FloorPlan Image on Map ━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !updatedCurrentFloorPlan) return

		addImageLayerToMap({
			map,
			floorPlan: updatedCurrentFloorPlan,
			mapStyleChanged,
			newStyleLayer
		})
		// }
	}, [map, updatedCurrentFloorPlan, mapStyleChanged, newStyleLayer])

	// ━━━━━━━━━━━━ GeoJson Areas ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !updatedCurrentFloorPlan) return

		updatedCurrentFloorPlan &&
			ActionHelpers.addGeoJsonLayerToMap({
				map,
				geoJson: updatedCurrentFloorPlan?.geoJson,
				// geoJson: selectedFloorPlan.geoJson,
				layerTypes: selectedCustomer?.layerTypes
			})
	}, [map, updatedCurrentFloorPlan, selectedCustomer, mapStyleChanged])

	// ━━━━━━━━ Count people in Areas   ━━━━━━━━ \\
	useEffect(() => {
		if (!map || !updatedCurrentFloorPlan) return

		// Count people inside areas
		const peopleCount = peopleCountInAreas({
			tags: oldTags,
			floorPlan: updatedCurrentFloorPlan,
			areas,
			showAreaInfoMarkers
		})

		peopleCount && setPeopleInsideEachArea(peopleCount)
	}, [map, oldTags, updatedCurrentFloorPlan, areas, showAreaInfoMarkers])

	// ━━━━━━━━ Areas markers  ━━━━━━━━ \\
	useEffect(() => {
		if (!map) return

		areaMarkers && areaMarkers.forEach(marker => marker.remove())
		if (!updatedCurrentFloorPlan) return

		const newZoneMarkers = addZoneMarkers({
			map,
			// tags: oldTags,
			floorPlan: updatedCurrentFloorPlan,
			areas,
			setGetDirections,
			geoJsonRoutesUndefinedSnackBar,
			// setTravelTo: setTravelTo,
			// setCopyOfStartPointCoords,
			setClickedToResetPath,
			containerRef: mapContainerRef,
			isFullscreen: isFullscreen,
			setOpenDrawerBar,
			setDrawerOptions,
			setEndingPoint,
			markersSize,
			showAreaInfoMarkers,
			showLayersBesidesTags,
			areasSize,
			peopleInsideEachArea
		})

		setAreaMarkers(newZoneMarkers)
	}, [
		map,
		// oldTags,
		updatedCurrentFloorPlan,
		areas,
		mapContainerRef,
		isFullscreen,
		showAreaInfoMarkers,
		showLayersBesidesTags,
		areasSize,
		mapStyleChanged,
		peopleInsideEachArea
	])

	// ━━━━━━ Points of interest markers ━━━━━━ \\
	useEffect(() => {
		if (!map) return

		poiMarkers && poiMarkers.forEach(marker => marker.remove())

		if (!pointsOfInterest) return

		const newPoiMarkers = addPOIMarkers({
			map,
			pointsOfInterest,
			floorPlanId: updatedCurrentFloorPlan?.id,
			iconsFromDB,
			setGetDirections,
			floorPlan: updatedCurrentFloorPlan,
			geoJsonRoutesUndefinedSnackBar,
			// setTravelTo: setTravelTo,
			// setCopyOfStartPointCoords: setCopyOfStartPointCoords,
			setClickedToResetPath: setClickedToResetPath,
			containerRef: mapContainerRef,
			isFullscreen: isFullscreen,
			setOpenDrawerBar,
			setDrawerOptions,
			setEndingPoint,
			markersSize,
			showPOIs,
			showLayersBesidesTags,
			POISSize
		})

		setPoiMarkers(newPoiMarkers)
	}, [
		map,
		updatedCurrentFloorPlan,
		pointsOfInterest,
		showPOIs,
		iconsFromDB,
		mapContainerRef,
		isFullscreen,
		showLayersBesidesTags,
		POISSize,
		mapStyleChanged
	])

	//  ━━━━━━━━━━━━━━━━━━━━━━ Indoor Navigation ━━━━━━━━━━━━━━━━━━━━━━
	// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
	useEffect(() => {
		if (map && !updatedCurrentFloorPlan?.geoJsonRoutes && directionsControl) {
			map.removeControl(directionsControl) // ← if no routes drawed, remove directions control
			pathFound && setClickedToResetPath(true) // ← Reset Path
			// removePathLayers({ map }); // ← if no routes drawed, removes path (click to reset does the same so don't need this)
		}
	}, [directionsControl, map, updatedCurrentFloorPlan, pathFound])

	// ━━━━━━━━ When changing floor without connectors access (elevator, etc) it resets path ━━━━━━━━
	useEffect(() => {
		if (!connectorAccess.clicked) {
			// console.log("Changed floor!! selectedFloorPlan.id:", selectedFloorPlan.id);
			pathFound && setClickedToResetPath(true) // ← Reset Path
		}
	}, [connectorAccess.clicked, pathFound, selectedFloorPlan])

	// // ━━━━━━━━━━━━ Routes Directions Control Box ━━━━━━━━━━━━ \\
	// Read me: Please don't delete this, just let it commented.
	// useEffect(() => {
	// 	if (
	// //		!map ||
	// //		!selectedSite ||
	// //		!updatedCurrentFloorPlan ||
	// //		!updatedCurrentFloorPlan?.geoJsonRoutes ||
	// //		!floorPlans
	// 	)
	// 		return

	// 	if (updatedCurrentFloorPlan) {
	// //!! Read me: Please don't delete this, just let it commented. This adds the routes geoJson lines to test indoor navigation.
	// 		addGeoJsonLayerToMapLine({
	// 			map,
	// 			geoJson: updatedCurrentFloorPlan?.geoJsonRoutes
	// 		})
	// 	}
	// }, [
	// 	typeByDefault,
	// 	iconTab,
	// 	getDirections,
	// 	// travelTo,
	// 	map,
	// 	floorPlans,
	// 	updatedCurrentFloorPlan,
	// 	selectedSite,
	// 	user,
	// 	areas,
	// 	pointsOfInterest,
	// 	people,
	// 	tags
	// ])

	// ━━━━━━━━━━━━ Draw Path Line ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !updatedCurrentFloorPlan?.geoJsonRoutes) {
			return
		}

		// console.log("🚀  showPathOnMap:", showPathOnMap)

		if (pathFound && showPathOnMap) {
			addGeoJsonLayerToMapWithNewPath({
				map,
				pathFound: pathFound,
				// travelTo: travelTo,
				copyOfStartPointCoords: copyOfStartPointCoords,
				floorPlan: updatedCurrentFloorPlan,
				copyOfConnectorCoords: copyOfConnectorCoords,
				poiToDoorCoords: poiToDoorCoords,
				poiToDoorEndPointCoords: poiToDoorEndPointCoords,
				endingPoint,
				startingPoint
			})
		}
	}, [
		poiToDoorEndPointCoords,
		poiToDoorCoords,
		updatedCurrentFloorPlan,
		copyOfStartPointCoords,
		// travelTo,
		map,
		pathFound,
		copyOfConnectorCoords,
		showPathOnMap,
		endingPoint,
		startingPoint
	])

	// ━━━━━━━━━━━━ Reset Path Control Button  ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map) return
		setClickedToResetPath(false)

		// Read me: Commented the old reset path control button on map
		// const resetPath = new ResetPathControl({
		//   resetPathButton,
		// });

		// if (resetPathControl) {
		//   map.removeControl(resetPathControl);
		// }

		// if (pathFound) {
		//   map.addControl(resetPath, "top-left");
		//   setResetPathControl(resetPath);
		// }

		if (clickedToResetPath) {
			// console.log("🚀 ~ clickedToResetPath", clickedToResetPath);

			removePathLayers(map) // Removes Path layer
			pathFound && setPathFound()
			setConnectorAccess({
				clicked: false,
				zIndex: "0",
				id: null
			})
		}
	}, [map, pathFound, clickedToResetPath])

	// ━━━━━━ Reset Path Control Button Function
	const resetPathButton = () => {
		pathFound && setClickedToResetPath(true)
		setEndingPoint()
		setStartingPoint()
		setCopyOfStartPointCoords()
		// ↓ Empty start point on directions box
		setGetDirections({
			type: null,
			poi: null,
			clicked: false
		})
	}

	// ━━━━━━ If selectedSite changes it removes the resetPathControl
	useEffect(() => {
		if (!map) return
		if (resetPathControl) {
			map.removeControl(resetPathControl)
		}
	}, [map, selectedSite])

	// ━━━━━━━━━━━━ Connectors GeoJsonRoutes and Set Path when they are in other floors ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !selectedSite || !floorPlans || !updatedCurrentFloorPlan?.geoJsonRoutes) return

		connectorsMarkers && connectorsMarkers.forEach(marker => marker.remove())

		// console.log(showPathOnMap, "showPathOnMap 👾👾👾👾")

		const newConnectorsMarkers = addConnectorsMarkers({
			map,
			connectorsMarkers,
			floorPlanId: updatedCurrentFloorPlan.id,
			floorPlanData: floorPlans,
			// allFloors: floorPlans, // not needed anymore
			geoJsonRoutes: updatedCurrentFloorPlan.geoJsonRoutes,
			connectorAccess: connectorAccess,
			startingPoint: startingPoint,
			endingPoint: endingPoint,
			// travelTo: travelTo,
			selectedFloor: updatedCurrentFloorPlan,
			dispatch: dispatch,
			setPathFound: setPathFound,
			// setGetDirections: setGetDirections,
			setConnectorAccess: setConnectorAccess,
			// setTravelTo: setTravelTo,
			copyOfConnectorCoords: copyOfConnectorCoords,
			setCopyOfStartPointCoords: setCopyOfStartPointCoords,
			geoJsonRoutesUndefinedSnackBar: geoJsonRoutesUndefinedSnackBar,
			zones: areas,
			setPoiToDoorEndPointCoords,
			setPoiToDoorCoords,
			containerRef: mapContainerRef,
			isFullscreen: isFullscreen,
			showPathOnMap,
			markersSize,
			showConnectors,
			showLayersBesidesTags
		})
		setConnectorsMarkers(newConnectorsMarkers)
	}, [
		selectedSite,
		copyOfConnectorCoords,
		getDirections,
		directionsControl,
		// travelTo,
		updatedCurrentFloorPlan,
		map,
		showConnectors,
		floorPlans,
		connectorAccess,
		areas,
		mapContainerRef,
		isFullscreen,
		showPathOnMap,
		startingPoint,
		endingPoint,
		showLayersBesidesTags,
		mapStyleChanged
	])

	// Show role marker even if it's not being displayed by the filter
	const showRoleAndTypeFunction = group => {
		if (group === "People") {
			setRoleFilterButtonStates(roles && roles.map(rol => rol.name || []))
		}
		if (group === "Assets") {
			setAssetFilterButtonStates(
				// differentAssetTypes && differentAssetTypes.map(asset => asset.type || [])
				differentAssetTypes && differentAssetTypes.map(asset => asset.assetDetails.type || [])
			)
		}
	}

	// ━━━━━━━━━━━━━ Drawer Preferences saved - set preferences ━━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!selectedSite) return

		if (drawerPreferences && drawerPreferences.length !== 0 && user) {
			filterPreferencesHandler({
				drawerPreferences,
				user,
				setShowAreaInfoMarkers,
				setShowPeople,
				setShowAssets,
				setShowUnassignedTags,
				setShowPOIs,
				setShowConnectors,
				setShowAnchors,
				setShowGateways,
				setRoleFilterButtonStates,
				setAssetFilterButtonStates,
				setMarkersSize,
				roles,
				differentAssetTypes
			})
		}
	}, [selectedSite, user, drawerPreferences, drawerOptions.openHeatmap])

	// ━━━━━━━━ Search on dashboard map ━━━━━━━━ \\
	useEffect(() => {
		if (!selectedFloorPlan) return
		if (selectedFloorPlan) {
			setNewSearchedTag(null)
		}
	}, [selectedFloorPlan])
	useEffect(() => {
		if (!map) return

		if (newSearchedTag) {
			handleSearchChange({
				dispatch,
				map: map,
				floorPlans: floorPlans,
				floorPlan: updatedCurrentFloorPlan,
				value: newSearchedTag,
				setGoTo: setGoTo,
				setShowPeople: setShowPeople,
				setShowAssets: setShowAssets,
				setShowPOIs: setShowPOIs,
				setShowAreaInfoMarkers: setShowAreaInfoMarkers,
				showRoleAndTypeFunction: showRoleAndTypeFunction,
				markersSize: markersSize,
				// devices: props.devices, // not in use, yet.
				// bleDevices: props.bleDevices, // not in use, yet.
				tags: tags
			})
		} else {
			resetZoomOnMap({ map, updatedCurrentFloorPlan, setGoTo: setGoTo })
		}
	}, [floorPlans, map, newSearchedTag, updatedCurrentFloorPlan, tags])

	// ━━━━━━━━━━━━━ Drawer Options ━━━━━━━━━━━━━ \\
	// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\
	useEffect(() => {
		// Note: typingSearch is a boolean for when user is typing on the search input autocomplete
		if (!map || typingSearch) return

		if (drawerControl && !typingSearch) map.removeControl(drawerControl)

		const _drawerControl = new DrawerOptions({
			selectedSite,
			openDrawerBar,
			setOpenDrawerBar,
			drawerOptions, // → all drawer options object
			setDrawerOptions,
			// ━━━ ↓ Search props
			access,
			map,
			dispatch: dispatch,
			people: people,
			assets: assets,
			tags: tags,
			floorPlans: floorPlans,
			floorPlan: updatedCurrentFloorPlan,
			userEmail: user.email,
			// devices: devices, // not in use, yet.
			// bleDevices: blePeople,  // not in use, yet.
			// buddies: users,  // not in use, yet.
			areas: areas,
			// things: things && things.filter(val => val.ble),  // not in use, yet.
			// bleThings: bleThings,  // not in use, yet.
			pois: pointsOfInterest,
			// sensors: sensors,  // not in use, yet.
			goTo: goTo,
			setGoTo: setGoTo,
			searchFilteredOptions,
			setSearchFilteredOptions,
			showRoleAndTypeFunction,
			setTypingSearch,
			setNewSearchedTag,
			// ━━━ ↓ Filter props
			showAreaInfoMarkers,
			setShowAreaInfoMarkers,
			showPeople,
			setShowPeople,
			showAssets,
			setShowAssets,
			showUnassignedTags,
			setShowUnassignedTags,
			showPOIs,
			setShowPOIs,
			showConnectors,
			setShowConnectors,
			showAnchors,
			setShowAnchors,
			showGateways,
			setShowGateways,
			searchedPersonOrAssetOld,
			setSearchedPersonOrAssetOld,
			// ━━━ ↓ Path / Routes props
			// floorPlanId: updatedCurrentFloorPlan?.id,
			getDirections: getDirections,
			setGetDirections: setGetDirections,
			geoJsonRoutes: updatedCurrentFloorPlan?.geoJsonRoutes,
			setPathFound: setPathFound,
			setConnectorAccess: setConnectorAccess,
			iconTab: iconTab,
			setIconTab: setIconTab,
			typeByDefault: typeByDefault,
			setTypeByDefault: setTypeByDefault,
			copyOfConnectorCoords: copyOfConnectorCoords,
			setCopyOfConnectorCoords: setCopyOfConnectorCoords,
			setPoiToDoorCoords: setPoiToDoorCoords,
			setPoiToDoorEndPointCoords: setPoiToDoorEndPointCoords,
			showPathOnMap,
			setShowPathOnMap,
			resetPathButton,
			// pathFound,
			endingPoint,
			setEndingPoint,
			startingPoint,
			setStartingPoint,
			geoJsonRoutesUndefinedSnackBar,
			selectedConnector,
			setSelectedConnector,
			setCopyOfStartPointCoords,
			// ━━━ ↓ Occupancy filter props
			showRoles,
			setShowRoles,
			handleClickRolesButton,
			roles,
			roleFilterButtonStates,
			setRoleFilterButtonStates,
			user,
			drawerPreferences,
			assetFilterButtonStates,
			setAssetFilterButtonStates,
			differentAssetTypes,
			setDifferentAssetTypes,
			// ━━━ ↓ Layers / Satellite views props
			changeMapStyle,
			mapStyleView,
			setMapStyleView,
			setMapStyleChanged,
			// ━━━ ↓ Markers Size Slider
			markersSize,
			setMarkersSize,
			// ━━━ ↓ Heatmap filter
			heatmapFilter,
			setHeatmapFilter
		})

		map.addControl(_drawerControl, "top-right")

		setDrawerControl(_drawerControl)

		// cleanup function (when component unmounts)
		return () => {
			// remove drawer tooltips overlays
			const toolTipElement = document.querySelector(".drawer_tooltips")
			const toolTipInnerElement = document.querySelector(".tooltip-inner")

			if (toolTipElement) {
				setTimeout(() => {
					toolTipElement.remove()
				}, [200])
			}
			if (toolTipInnerElement) {
				setTimeout(() => {
					toolTipInnerElement.remove()
				}, [200])
			}
		}
	}, [
		map,
		selectedSite,
		openDrawerBar,
		drawerOptions,
		showAreaInfoMarkers,
		showPeople,
		showAssets,
		showUnassignedTags,
		showPOIs,
		showConnectors,
		people,
		assets,
		tags,
		floorPlans,
		updatedCurrentFloorPlan,
		user.email,
		areas,
		pointsOfInterest,
		searchFilteredOptions,
		typeByDefault,
		iconTab,
		getDirections,
		showPathOnMap,
		// // pathFound,
		endingPoint,
		startingPoint,
		selectedConnector,
		copyOfConnectorCoords,
		showRoles,
		roles,
		roleFilterButtonStates,
		user,
		drawerPreferences,
		mapStyleView,
		showAnchors,
		showGateways,
		searchedPersonOrAssetOld,
		assetFilterButtonStates,
		differentAssetTypes,
		heatmapFilter,
		//	// goTo,
		typingSearch,
		access
	])

	// close drawer bar if the site is changed
	useEffect(() => {
		if (!selectedSite) return

		setOpenDrawerBar(false)
	}, [selectedSite])

	// Close search bar if after searching something the user changes floorplan
	useEffect(() => {
		if (!updatedCurrentFloorPlan) return

		let timeoutId

		if (goTo && goTo?.floorPlanId !== updatedCurrentFloorPlan?.id) {
			setGoTo()
			setDrawerOptions({
				openSearch: false,
				openFilter: false,
				openPath: false,
				openOccupancy: false,
				openSatellite: false,
				open3d: false
			})
		} else {
			if (map) {
				// after searching a person remove the green circle layer after 2 secs
				map.on("zoomstart", () => {
					timeoutId = setTimeout(() => {
						const layers = map.getStyle()
						if (layers) {
							var goToLayer = map.getLayer("goToThingMarker")
							// Check if the layer exists
							if (goToLayer) {
								// Change the visibility of the layer
								map.setLayoutProperty("goToThingMarker", "visibility", "none") // 'none' to hide the layer, 'visible' to show it
							}
						}
					}, 3000)
				})
			}
		}

		return () => {
			clearTimeout(timeoutId) // Clear timeout on unmount or dependencies change
		}
	}, [map, updatedCurrentFloorPlan, goTo])

	// Filter assets for all unique types
	useEffect(() => {
		if (!assets) return

		const filteredAssets = uniqAssetDetailsTypes(assets)
		filteredAssets && setDifferentAssetTypes(filteredAssets)
		filteredAssets &&
			setAssetFilterButtonStates(
				filteredAssets.map(asset => asset?.assetDetails?.type && asset.assetDetails?.type)
			)
		// If we have drawerPreferences instead of the filteredAssets set the preferences to setAssetFilterButtonStates()
		// if (drawerPreferences && drawerPreferences.length !== 0 && user) {
		// 	const foundedPreferences = drawerPreferences.find(val => val.userId === user?.id)

		// 	if (foundedPreferences) {
		// 		foundedPreferences?.preferences?.assetTypeFilter &&
		// 			setAssetFilterButtonStates(
		// 				foundedPreferences?.preferences?.assetTypeFilter.map(asset => asset)
		// 			)
		// 	} else {
		// 		// filteredAssets && setAssetFilterButtonStates(filteredAssets.map(val => val.type))
		// 		filteredAssets &&
		// 			setAssetFilterButtonStates(filteredAssets.map(val => val.assetDetails.type))
		// 	}
		// } else {
		// 	// filteredAssets && setAssetFilterButtonStates(filteredAssets.map(val => val.type))
		// 	filteredAssets &&
		// 		setAssetFilterButtonStates(filteredAssets.map(val => val.assetDetails.type))
		// }
	}, [assets, drawerPreferences, user])

	// ━━━━━━ Keep clickedRolesButton state to false according to showRoles state
	useEffect(() => {
		// This code will run once when the component mounts
		setClickedRolesButton(false)
	}, [showRoles])

	const handleClickRolesButton = () => {
		setClickedRolesButton(true)
	}

	// ━━━━━━ Split the markersSize object into different states
	// Note: so when user changes each size, doesn't affect other layer markers
	useEffect(() => {
		if (!markersSize) return

		if (markersSize.people || markersSize.people === 0) {
			setPeopleSize(markersSize.people)
		}
		if (markersSize.assets || markersSize.assets === 0) {
			setAssetsSize(markersSize.assets)
		}
		if (markersSize.pois || markersSize.pois === 0) {
			setPOISSize(markersSize.pois)
		}
		if (markersSize.anchors || markersSize.anchors === 0) {
			setAnchorsSize(markersSize.anchors)
		}
		if (markersSize.gateways || markersSize.gateways === 0) {
			setGatewaysSize(markersSize.gateways)
		}
		if (markersSize.unassignedTags || markersSize.unassignedTags === 0) {
			setUnassignedTagsSize(markersSize.unassignedTags)
		}
		if (markersSize.areasInfo || markersSize.areasInfo === 0) {
			setAreasSize(markersSize.areasInfo)
		}
	}, [markersSize])

	// ━━━━━━━━━━━━ MapboxglSpiderifier ━━━━━━━━━━━━
	useEffect(() => {
		if (!map) return

		const spiderifier = new MapboxglSpiderifier(map, {
			customPin: true,
			animate: true,
			animationSpeed: 200,
			circleSpiralSwitchover: 15,
			initializeLeg: spiderLeg =>
				initializeSpiderLeg(
					spiderLeg,
					markersSize,
					roles,
					updatedCurrentFloorPlan,
					areas,
					isFullscreen,
					mapContainerRef,
					setSendMessageModal,
					setGetDirections,
					geoJsonRoutesUndefinedSnackBar,
					setClickedToResetPath,
					setOpenDrawerBar,
					setDrawerOptions,
					setEndingPoint,
					dispatch,
					customClaims
				)
		})

		let lastClickedClusterId = null

		// Show spiderifier onClick cluster
		map.on("click", "cluster-pins", e => {
			// console.log("🚀  map.getZoom():", map.getZoom())
			const clusterId = e?.features?.[0]?.properties?.cluster_id

			if (clusterId === lastClickedClusterId) {
				// Clicked the same cluster marker again
				spiderifier.unspiderfy()
				lastClickedClusterId = null

				removePopupSticky() // make sure that the popover popup doesn't appear on screen
			} else {
				// Clicked a new cluster marker
				mouseClick(e, map, spiderifier)
				lastClickedClusterId = clusterId

				removePopupSticky() // make sure that the popover popup doesn't appear on screen
			}
		})

		map.on("zoomstart", () => {
			spiderifier.unspiderfy()
			lastClickedClusterId = null

			removePopupSticky() // make sure that the popover popup doesn't appear on screen
		})

		// listener: if fullScreen is changed unspiderfy() all clusters
		document.addEventListener("fullscreenchange", function () {
			if (document.fullscreenElement) {
				spiderifier.unspiderfy()
				lastClickedClusterId = null
			} else {
				spiderifier.unspiderfy()
				lastClickedClusterId = null
			}
		})

		return () => {
			map.off("click", "cluster-pins")
			map.off("zoomstart")
		}
	}, [map, roles, updatedCurrentFloorPlan, areas, isFullscreen, mapContainerRef, customClaims])

	// ━━━━━━━━━━━━ Change Floors Control Button ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map) return

		if (floorsControl) map.removeControl(floorsControl)

		const _floorsControl = new ChangeFloorControlDashboard({
			dispatch: dispatch,
			selectedSite: selectedSite,
			selectedFloorPlan: selectedFloorPlan,
			floorPlans: floorPlans,
			handleClick,
			pathFound
		})
		map.addControl(_floorsControl, "bottom-left")
		setFloorsControl(_floorsControl)
	}, [floorPlans, selectedSite, selectedFloorPlan, map, pathFound])

	// ━━━━━ Select new floorPlan (control floors buttons)
	const handleClick = (floorId, pathFound) => {
		if (!floorId) return
		// Remove path and reset states if drawed when changing floors "manually"
		if (pathFound) {
			removePathLayers(map) // Removes Path layer
			pathFound && setPathFound()
			setConnectorAccess({
				clicked: false,
				zIndex: "0",
				id: null
			})
		}

		const foundedFloorPlan = floorPlans && floorPlans.find(f => f.id === floorId)

		foundedFloorPlan && dispatch(actions.floorPlanSelected(foundedFloorPlan)) // changes floorplan
	}

	// ━━━━━━━━━━━ Gateways ━━━━━━━━━━━ \\
	useEffect(() => {
		ActionHelpers.addGatewaysToMap({
			dispatch,
			customClaims,
			map,
			selectedCustomer,
			selectedSite,
			selectedFloorPlan,
			gateways,
			gatewaysMarkers,
			setGatewaysMarkers,
			showGateways,
			markersSize,
			// showLayersBesidesTags,
			containerRef: mapContainerRef,
			isFullscreen,
			gatewaysSize
		})
	}, [
		map,
		selectedCustomer,
		customClaims,
		selectedSite,
		selectedFloorPlan,
		showGateways,
		gateways,
		mapContainerRef,
		isFullscreen,
		// showLayersBesidesTags,
		gatewaysSize,
		mapStyleChanged
	])

	// ━━━━━━━━━━━ Anchors ━━━━━━━━━━━ \\
	useEffect(() => {
		ActionHelpers.addAnchorsToMap({
			dispatch,
			customClaims,
			map,
			selectedCustomer,
			selectedSite,
			selectedFloorPlan,
			anchors,
			anchorsMarkers,
			setAnchorsMarkers,
			showAnchors,
			markersSize,
			// showLayersBesidesTags,
			containerRef: mapContainerRef,
			isFullscreen,
			anchorsSize,
			mapStyleChanged
		})
	}, [
		selectedCustomer,
		customClaims,
		map,
		selectedSite,
		selectedFloorPlan,
		showAnchors,
		anchors,
		mapContainerRef,
		isFullscreen,
		// showLayersBesidesTags,
		anchorsSize
	])

	// ━━━━━━━━━━━ Tags ━━━━━━━━━━━ \\
	useEffect(() => {
		ActionHelpers.addTagsToMap({
			map,
			selectedFloorPlan: updatedCurrentFloorPlan,
			selectedSite: selectedSite,
			unfilteredTags: tags,
			roles,
			assetTypes,
			setSendMessageModal,
			customClaims,
			areas,
			markers,
			setMarkers,
			oldTags,
			setOldTags,
			containerRef: mapContainerRef,
			setGetDirections,
			geoJsonRoutesUndefinedSnackBar,
			// setTravelTo: setTravelTo,
			// setCopyOfStartPointCoords,
			setClickedToResetPath,
			isFullscreen,
			tagsUpdatedWithFullscreen,
			setTagsUpdatedWithFullscreen,
			showTags,
			showPeople,
			showAssets,
			showUnassignedTags,
			setOpenDrawerBar,
			setDrawerOptions,
			setEndingPoint,
			people,
			dispatch,
			showRoles,
			clickedRolesButton,
			setClickedRolesButton,
			roleFilterButtonStates,
			assetFilterButtonStates,
			markersSize,
			peopleSize,
			assetsSize,
			unassignedTagsSize,
			notificationWarning,
			companies
		})
	}, [
		map,
		updatedCurrentFloorPlan,
		selectedSite,
		tags,
		roles,
		assetTypes,
		areas,
		customClaims,
		mapContainerRef,
		isFullscreen,
		showTags,
		showPeople,
		showAssets,
		showUnassignedTags,
		people,
		showRoles,
		clickedRolesButton,
		roleFilterButtonStates,
		assetFilterButtonStates,
		peopleSize,
		assetsSize,
		unassignedTagsSize,
		notificationWarning,
		mapStyleChanged,
		companies
	])

	// ━━━━━━━━━━━ Notifications Warning ━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !notificationWarning) return
		geofencePulseMarkers && geofencePulseMarkers.forEach(marker => marker.remove())

		const element = document.getElementsByClassName("warning_tag")

		if (element) {
			element.forEach(val => {
				val.remove()
			})
		}

		//note: this setTimeout function is to display the geofence pulse at the same time as the tags markers layers
		setTimeout(() => {
			activateGeofence({
				map,
				notificationWarning,
				tags,
				markersSize,
				dispatch,
				actions,
				notificationsActions,
				updatedCurrentFloorPlan,
				geofencePulseMarkers,
				setGeofencePulseMarkers,
				floorPlans
			})
		}, 1000)
	}, [notificationWarning, map, tags, updatedCurrentFloorPlan, floorPlans])

	// ━━━━━━━━━━━ Hide html POIS, Areas, Connectors, etc markers with popups when clusters are active when starting map
	useEffect(() => {
		if (!map || !updatedCurrentFloorPlan) return

		const defaultZoom = 18
		const zoomConditionalLevel = updatedCurrentFloorPlan?.mapOrientation?.zoom
			? updatedCurrentFloorPlan?.mapOrientation?.zoom
			: defaultZoom

		hideMarkersWhenClustering({
			map,
			// pointsOfInterest,
			// areas,
			// anchors,
			// gateways,
			setShowLayersBesidesTags,
			showPathOnMap,
			zoomConditionalLevel
		})
	}, [
		map,
		showPathOnMap,
		updatedCurrentFloorPlan
		// pointsOfInterest, areas, anchors, gateways
	])

	// ━━━━━━━━━━━ Hide html POIS, Areas, Connectors, etc markers with popups when clusters are active when zooming map
	useEffect(() => {
		if (!map || !updatedCurrentFloorPlan) return

		// Note: this is not used now but it is a good idea to use it in the future.
		// const defaultZoom = 18
		// const zoomConditionalLevel = updatedCurrentFloorPlan?.mapOrientation?.zoom
		// 	? updatedCurrentFloorPlan?.mapOrientation?.zoom
		// 	: defaultZoom
		const zoomConditionalLevel = 17.5

		map.on("zoomend", () => {
			hideMarkersWhenClusteringOnZoom(
				map,
				zoomConditionalLevel,
				showPathOnMap,
				setShowLayersBesidesTags
			)
		})
	}, [map, updatedCurrentFloorPlan, showPathOnMap])

	// ━━━━━━━━━━━━━ No Location Selected Map ━━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !selectedCustomer) return

		const allSites = selectedCustomer?.sites

		// If customer has more than one location
		if (!selectedSite && allSites && allSites.length > 1) {
			allLocationsInMap({
				map,
				selectedCustomer,
				selectedSite,
				locationsMarkers,
				// sensorMarkers, // we don't have sensorMakers yet
				allSites,
				dispatch,
				setLocationsMarkers,
				floorPlans,
				markersSize
				// workers,
			})
			// Remove info markers and clusters in no location view
			setOpenDrawerBar(false) // close drawer options bar
			setDrawerOptions({
				openSearch: false,
				openFilter: false,
				openPath: false,
				openOccupancy: false,
				openSatellite: false,
				openSlider: false
			})
			setStartingPoint() // resets starting point
			setEndingPoint() // resets ending point
			setShowPathOnMap(false)
			// ↓ don't display markers
			setShowAreaInfoMarkers(false) // don't display markers
			setShowTags(false)
			setShowPOIs(false)
			setShowConnectors(false)
			connectorsMarkers && connectorsMarkers.forEach(marker => marker.remove()) // remove connectors markers completely
			setShowAnchors(false)
			anchorsMarkers && anchorsMarkers.forEach(marker => marker.remove()) // remove anchors markers
			setGatewaysMarkers(false)
			gatewaysMarkers && gatewaysMarkers.forEach(marker => marker.remove()) // remove gateways markers
		}

		// Display userPreferences markers of default and remove location markers if selectedSite exists
		if (selectedSite) {
			locationsMarkers &&
				locationsMarkers.forEach(m => {
					m.remove()
				})

			const foundedPreferences =
				user && drawerPreferences && drawerPreferences.find(val => val?.userId === user?.id)

			setShowTags(true)
			if (foundedPreferences) {
				// setShowPeople(
				// 	foundedPreferences?.preferences?.people
				// 		? foundedPreferences?.preferences?.people
				// 		: true
				// )
				// setShowAssets(
				// 	foundedPreferences?.preferences?.assets
				// 		? foundedPreferences?.preferences?.assets
				// 		: true
				// )
			}

			// 	// setShowPeople(prevState => {
			// 	// 	console.log("🚀 ~ file: LiveMapWidget.js:1024 ~ useEffect ~ prevState:", prevState)
			// 	// 	return preferences?.people ? preferences?.people : prevState
			// 	// })
			// 	// setShowAssets(preferences?.assets || false)
			// 	setShowAssets(prevState => (preferences?.assets ? preferences?.assets : prevState))
			// 	// setShowUnassignedTags(preferences?.unassignedTags || false)
			// 	setShowUnassignedTags(prevState =>
			// 		preferences?.unassignedTags ? preferences?.unassignedTags : prevState
			// 	)
			// 	// setShowPOIs(preferences?.pois || false)
			// 	setShowPOIs(prevState => (preferences?.pois ? preferences?.pois : prevState))
			// 	// setShowConnectors(preferences?.connectors || false)
			// 	setShowConnectors(prevState =>
			// 		preferences?.connectors ? preferences?.connectors : prevState
			// 	)
			// }

			// Old code
			// setShowOccupancy(false);
			// setShowZoneInfoMarkers(true);
			// setShowEmployees(true);
			// setShowGuests(true);
			// setShowVisitors(true);
			// setShowPOIs(true);
			// setShowSensors(true);
			// setShowConnectors(true);
			// setShowAPS(false);
			// setShowHeatMap(false);
		}
	}, [
		map,
		selectedSite,
		selectedCustomer,
		selectedCustomer?.id,
		selectedCustomer?.sites,
		floorPlans,
		user,
		drawerPreferences
		// markersSize
		// workers,
	])

	// ━━━━━━━━━━━━━ Update Map For Heatmap ━━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !updatedCurrentFloorPlan || !tags) return

		addHeatmaptoMap({
			map,
			tags,
			selectedFloorPlan: updatedCurrentFloorPlan,
			setShowPeople,
			setShowAssets,
			setShowUnassignedTags,
			setShowAnchors,
			setShowGateways,
			setShowPOIs,
			setShowAreaInfoMarkers,
			setShowConnectors,
			drawerOptions,
			setDrawerOptions,
			heatmapFilter,
			drawerPreferences,
			user
		})
	}, [drawerOptions, map, updatedCurrentFloorPlan, tags, heatmapFilter, drawerPreferences, user])

	// ━━━━━━━━━━━ Machinery mock geofencing ━━━━━━━━━━━ \\
	// Only uncommented this if we need for demos
	// //!! Readme: Don't delete this please.
	// useEffect(() => {
	//   if (!map) return;

	//   if (
	//     selectedCustomer?.id === "94riNwfW4Y7kPm6lVPcY" &&
	//     selectedFloorPlan?.id === "uhd8SZmsEKnmRE7FXO0A"
	//   ) {
	//     addMockMarkersToMap({
	//       map,
	//       showMockMarkers,
	//       setShowMockMarkers,
	//       floorPlan: selectedFloorPlan?.id,
	//       areas,
	//       setOpenAlertModal,
	//     });
	//   } else {
	//     showMockMarkers &&
	//       dispatch({
	//         type: "SNACKBAR_WARNING",
	//         payload: `This floorplan doesn't have any heavy machinery.`,
	//       });
	//   }
	// }, [selectedFloorPlan, map, showMockMarkers, selectedCustomer, areas]);

	function geoJsonRoutesUndefinedSnackBar(floor) {
		if (!floor) return

		const paragraphToaster = (
			<p>
				No routes available in floor {floor?.name}. <br />
				{customClaims.isSuper && <span>Please draw routes in design studio page.</span>}
			</p>
		)
		dispatch({
			type: "SNACKBAR_INFO",
			payload: paragraphToaster
		})
	}

	const sendMessageModalOnHide = () => setSendMessageModal()

	return (
		<Card>
			<CardBody padding="0px" id="dashboard_live_map_widget_card">
				<>
					{/* //	Readme: Don't delete this button. This is when we need to create more nodes to test. */}
					{/* <button
						onClick={() => {
							const randomPoints = ActionHelpers.addRandomPointsInBox({
								map,
								floorPlan: updatedCurrentFloorPlan
							})

							const newNodesArray = randomPoints.map((point, index) => {
								new mapboxgl.Marker().setLngLat(point).addTo(map) // add markers to map

								const generatedUUID = `700${index}`
								// Note: Doc that start with 7 are going to be theses mock tags
								const newNode = {
									mockNode: true, // This prop is to delete the nodes finding this prop
									floorPlanId: updatedCurrentFloorPlan.id,
									siteId: selectedSite.id,
									gpsData: new firebase.firestore.GeoPoint(point[1], point[0]),
									status: "online",
									tagState: "active",
									nodeType: "tag",
									networkAddress: 20241333,
									uuid: Number(generatedUUID),
									is_approved: true,
									geoHash: "dqcx6p8e8myqce1rnw6b"
								}

								return newNode
							})
							// console.log("🚀  newNodesArray:", newNodesArray)

							newNodesArray &&
								liveDataActions.createNodesInNodesCollection({
									selectedSite,
									newNodesArray
								})
						}}
					>
						Create nodes
					</button> */}
					<div
						style={{
							height: "600px",
							borderRadius: "0 0 5px 5px"
						}}
						ref={mapContainerRef}
					/>
				</>
			</CardBody>

			{/* ━━━━━━━━ AlertGeoFencing Modal ━━━━━━━━ */}
			<AlertGeofencing
				openAlertModal={openAlertModal}
				setOpenAlertModal={setOpenAlertModal}
				showMockMarkers={showMockMarkers}
				minutesSinceLastAlert={minutesSinceLastAlert}
				setMinutesSinceLastAlert={setMinutesSinceLastAlert}
			/>

			{/* ━━━━━━━━ Send Message Modal ━━━━━━━━ */}
			<SendMessageModal
				show={sendMessageModal ? true : false}
				onHide={sendMessageModalOnHide}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				person={sendMessageModal?.person}
				containerRef={mapContainerRef}
			/>
		</Card>
	)
}
